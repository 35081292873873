import React, { useState, useEffect } from 'react';
import potatoes from '../assets/potatoes.png';
import potatoesMobile from '../assets/potatoes-mobile.png';

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="grid h-screen grid-cols-1 md:grid-cols-5 gap-4">
      {/* Image Section */}
      <div className="flex justify-center items-center flex-col home pt-10 col-span-2">
        <img
          src={isMobile ? potatoesMobile : potatoes}
          alt="Potatoes"
          className="w-3/4 md:w-full max-w-sm md:max-w-md lg:max-w-lg"
        />
      </div>

      {/* Text Section */}
      <div className="flex justify-center gap-2 p-4 flex-col home pt-10 col-span-3">
        <div className="flex flex-col text-center md:text-left">
          <h1 className="text-4xl md:text-5xl lg:text-7xl text-darkmygreen font-bold leading-tight">
            Nature&apos;s Freshness,
            <br className="hidden md:block" />
            Just for You.
          </h1>
          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-700 mt-4">
            Experience Farm-Fresh Goodness, Grown with Heart and Hands
          </h2>
        </div>

        {/* Button Section */}
        <div className="mt-6 flex justify-center md:justify-start">
          <a
            href="#visit"
            className="w-36 text-center bg-darkmygreen text-white py-2.5 rounded-full shadow-md hover:shadow-lg hover:bg-green-700 transition duration-300"
          >
            Visit Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
