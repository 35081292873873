import React from 'react';
import potatoSeeds from '../assets/potato-seeds.jpg'; // Replace with actual path to the image
import onionSeedlings from '../assets/onion-seedlings.jpg'; // Replace with actual path to the image

const Product = () => (
  <div className="product pt-10 p-8">
    <h2 className="text-2xl font-bold text-center mb-6">Experience Farm-Fresh Goodness, Grown with Heart and Hands</h2>
    <p className="text-center mb-10">Explore our premium, certified seeds and seedlings, cultivated with sustainable practices to ensure quality and productivity.</p>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Certified Potato Seeds */}
      <div className="product-item bg-white p-4 rounded-lg shadow-lg">
        <img src={potatoSeeds} alt="Certified Potato Seeds" className="w-full h-48 object-cover rounded-md mb-4" />
        <h3 className="text-xl font-semibold mb-2">Certified Potato Seeds</h3>
        <p className="mb-4">
          Our certified potato seeds are selected for
          <br />
          optimal growth and yield. Each seed is cultivated
          <br />
          to provide resilience, strong growth, and excellent taste.
          Perfect for farms and home gardens alike, our
          <br />
          potatoes offer consistency and quality in every harvest.
        </p>
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Learn More</button>
      </div>

      {/* Onion Seedlings */}
      <div className="product-item bg-white p-4 rounded-lg shadow-lg">
        <img src={onionSeedlings} alt="Onion Seedlings" className="w-full h-48 object-cover rounded-md mb-4" />
        <h3 className="text-xl font-semibold mb-2">Robust Onion Seedlings</h3>
        <p className="mb-4">
          Our onion seedlings are grown for strength and adaptability,
          <br />
          ensuring larger, healthier onions. They’re ideal for commercial
          <br />
          and personal use, offering high resistance to disease and great
          <br />
          storage potential.
          Discover our seedlings that produce robust onions in every season.
        </p>
        <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Learn More</button>
      </div>
    </div>
  </div>
);

export default Product;
