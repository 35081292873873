// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="bg-mylightestgreen text-darkmygreen p-4 text-center">
      <p>
        &copy;
        {new Date().getFullYear()}
        {' '}
        JFAcres Farm. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
