import React, { useState, useEffect } from 'react';
import './App.scss';
import logo from './assets/logo.png';
import Home from './components/Home';
import About from './components/About';
import Product from './components/Product';
import Blog from './components/Blog';
import Visit from './components/Visit';
import Footer from './components/Footer';

function App() {
  const [navBg, setNavBg] = useState('transparent');
  const [showAlert, setShowAlert] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false); // State to control mobile menu

  useEffect(() => {
    const alertTimer = setTimeout(() => setShowAlert(false), 3000);

    const handleScroll = () => {
      const aboutSection = document.getElementById('about');
      const aboutTop = aboutSection.getBoundingClientRect().top;
      setNavBg(aboutTop <= 80 ? 'white' : 'transparent');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(alertTimer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      {/* Centered Alert Component */}
      {showAlert && (
        <div className="fixed top-1/2 left-1/2 transform w-full max-w-sm -translate-x-1/2 -translate-y-1/2 bg-yellow-200 text-yellow-800 p-4 text-center rounded shadow-lg">
          🚧 Under Construction: This page is still being built. Please check back soon!
        </div>
      )}

      <header
        className="text-white h-16 fixed top-0 flex items-center left-0 w-full z-10 transition-colors duration-300"
        style={{ backgroundColor: navBg }}
      >
        <nav className="w-full px-6 md:px-20 flex items-center justify-between">
          {/* Logo */}
          <img src={logo} alt="Logo" className="h-10" />

          {/* Hamburger Menu Button (visible on small screens) */}
          <button
            type="submit"
            label="ham"
            className="md:hidden flex items-center text-darkmygreen"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
              />
            </svg>
          </button>

          {/* Navigation Links */}
          <ul
            className={`fixed md:static top-16 left-0 w-full md:w-auto bg-white md:bg-transparent md:flex items-center justify-between text-lg transition-all duration-300 ${
              menuOpen ? 'block' : 'hidden'
            }`}
          >
            <li className="px-6 py-2 md:py-0">
              <a
                href="#home"
                className={`hover:text-green-300 ${
                  navBg === 'white' ? 'text-darkmygreen' : 'text-darkmygreen'
                }`}
              >
                Home
              </a>
            </li>
            <li className="px-6 py-2 md:py-0">
              <a
                href="#about"
                className={`hover:text-green-300 ${
                  navBg === 'white' ? 'text-darkmygreen' : 'text-darkmygreen'
                }`}
              >
                About
              </a>
            </li>
            <li className="px-6 py-2 md:py-0">
              <a
                href="#product"
                className={`hover:text-green-300 ${
                  navBg === 'white' ? 'text-darkmygreen' : 'text-darkmygreen'
                }`}
              >
                Product
              </a>
            </li>
            <li className="px-6 py-2 md:py-0">
              <a
                href="#blog"
                className={`hover:text-green-300 ${
                  navBg === 'white' ? 'text-darkmygreen' : 'text-darkmygreen'
                }`}
              >
                Blog
              </a>
            </li>
            <li className="px-6 py-2 md:py-0">
              <a
                href="#visit"
                className="px-3 border-darkmygreen border-2 text-darkmygreen rounded-full hover:bg-darkmygreen hover:text-white transition"
              >
                Visit
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <section id="home" className="min-h-screen pt-2">
          <Home />
        </section>
        <section id="about" className="min-h-screen">
          <About />
        </section>
        <section id="product" className="min-h-screen p-8">
          <Product />
        </section>
        <section id="blog" className="min-h-screen p-8 bg-mylightestgreen">
          <Blog />
        </section>
        <section id="visit" className="min-h-screen">
          <Visit />
        </section>
        <Footer />
      </main>
    </div>
  );
}

export default App;
