// src/components/Visit.js
import React from 'react';

function Visit() {
  return (
    <section id="visit" className="visit min-h-screen p-8 bg-mylightgreen flex flex-col items-center justify-center">
      <h2 className="text-3xl text-darkmygreen mb-4">Plan Your Visit</h2>
      <p className="text-lg text-center max-w-xl mb-6">
        We welcome visitors year-round! Experience our farm, learn about sustainable farming
        <br />
        practices,and see how we grow our certified potato seeds and onion seedlings.
        <br />
        Contact us to schedule your visit.
      </p>
      <button type="submit" className="bg-darkmygreen text-white px-6 py-2 rounded-full hover:bg-green-300 transition-colors">
        Visit
      </button>
    </section>
  );
}

export default Visit;
