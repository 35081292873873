import React from 'react';

const About = () => (
  <div className="about min-h-screen grid grid-cols-1 md:grid-cols-2">
    {/* Left Column */}
    <div className="bg-darkmygreen text-myyellow flex flex-col justify-center items-center rounded-tr-3xl p-8 space-y-4 md:space-y-6">
      <h2 className="text-2xl md:text-3xl font-bold text-center md:text-left">
        More than just a provider of fresh, organic produce
      </h2>
      <div className="w-full h-48 bg-myyellow flex items-center justify-center rounded-lg">
        {/* Placeholder for an Image */}
        <p className="text-darkmygreen font-semibold">Image of the Farm or Produce</p>
      </div>
    </div>

    {/* Right Column */}
    <div className="bg-mygreen text-white rounded-tl-3xl p-8 flex flex-col justify-center space-y-4 md:space-y-6">
      <h2 className="text-3xl font-bold">About Us</h2>
      <p className="text-lg leading-relaxed">
        Welcome to
        {' '}
        <strong>JFAcres Farm!</strong>
        {' '}
        Established in 2024, our farm began with a focus on
        growing high-quality Irish potatoes. Over time, we expanded to
        <br />
        include onions in our crops, using
        advanced, all-season technologies to ensure fresh, nutritious produce year-round.
      </p>
      <p className="text-lg leading-relaxed">
        At JFAcres, we&apos;re committed to sustainable farming practices and pride
        <br />
        ourselves on providing
        certified potato seeds and robust onion seedlings, ideal for
        <br />
        growing larger, healthier onions. Rooted
        in quality and innovation, we&apos;re dedicated to nurturing
        <br />
        our land and supporting our community with
        reliable, sustainably-grown produce.
      </p>
    </div>
  </div>
);

export default About;
