import React from 'react';

const Blog = () => (
  <div className="blog pt-10 p-8">
    <h2 className="text-darkmygreen text-2xl font-bold mb-6 text-center">Our Blog</h2>
    <p className="text-darkmygreen text-center mb-10">Explore our insights and tips on farming Irish potatoes and sustainable agricultural practices.</p>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* Blog Entry 1 */}
      <div className="blog-entry text-darkmygreen bg-mylightestgreen p-4 rounded-lg shadow-2xl">
        <h3 className="text-xl font-semibold mb-2">Introduction to Irish Potato Farming</h3>
        <p className="mb-4">Learn the basics of starting an Irish potato farm, from soil preparation to harvesting techniques.</p>
        <iframe
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/VIDEO_ID_1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Blog Entry 2 */}
      <div className="blog-entry p-4 text-darkmygreen rounded-lg shadow-2xl">
        <h3 className="text-xl font-semibold mb-2">Best Practices for Growing Irish Potatoes</h3>
        <p className="mb-4">Explore advanced tips and techniques for maximizing yield and quality of your potato crops.</p>
        <iframe
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/VIDEO_ID_2"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Blog Entry 3 */}
      <div className="blog-entry text-darkmygreen p-4 rounded-lg shadow-2xl">
        <h3 className="text-xl font-semibold mb-2">
          Sustainable Farming Practices
          <br />
          <br />
        </h3>
        <p className="mb-4">Learn about sustainable methods that can be applied to Irish potato farming to protect soil and water resources.</p>
        <iframe
          width="100%"
          height="200"
          src="https://www.youtube.com/embed/VIDEO_ID_3"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Add more blog entries as needed */}
    </div>
  </div>
);

export default Blog;
